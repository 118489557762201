import React from "react";
// import RequireDasboard from './RequireDasboard';

const SignUp1 = React.lazy(() =>
  import("./Pages/Authentication/SignUp/SignUp1")
);
const Signin1 = React.lazy(() =>
  import("./Pages/Authentication/SignIn/SignIn1")
);
const SendForgotMail = React.lazy(() =>
  import("./Pages/Authentication/sendforgotmail/SendForgotMail")
);
const ResetPassword = React.lazy(() =>
  import("./Pages/Authentication/resetpassword/ResetPassword")
);
const verfiyIdentity = React.lazy(() =>
  import("./Pages/Authentication/verify/verfiyIdentity")
);
const responseofsendmail = React.lazy(() =>
  import("./Pages/Authentication/responseofsendmail.js")
);
const VerifyEmail = React.lazy(() =>
  import("./Pages/Authentication/verifyEmail/VerifyEmail.js")
);
const ResendVerification = React.lazy(() =>
  import("./Pages/Authentication/resendVerification/ResendVerification.js")
);
const AdminLogin = React.lazy(() =>
  import("./Pages/Authentication/admin/AdminLogin.js")
);
const route = [
  { path: "/login", exact: true, name: "Signin 1", component: Signin1 },
  { path: "/signup", exact: true, name: "Signup 1", component: SignUp1 },
  {
    path: "/forgotpassword",
    exact: true,
    name: "SendForgotMail",
    component: SendForgotMail,
  },
  {
    path: "/newadd/:resettoken",
    exact: true,
    name: "New Add",
    component: responseofsendmail,
  },
  {
    path: "/reset/:token",
    exact: true,
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/verify/:email",
    exact: true,
    name: "verfiyIdentity",
    component: verfiyIdentity,
  },
  {
    path: "/verifyEmail",
    exact: true,
    name: "verifyEmail",
    component: VerifyEmail,
  },
  {
    path: "/resend-verification",
    exact: true,
    name: "resend-verification",
    component: ResendVerification,
  },
  {
    path: "/admin-dashboard",
    exact: true,
    name: "admin-dashboard",
    component: AdminLogin,
  },
];

export default route;
